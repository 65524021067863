<template>
  <div class="-advanced_dojo">
    <div class="c_main -pad-btm">
      <header class="c_main_hd">
        <div class="inner">
          <nav class="c_pnkz">
            <ul class="c_pnkz_list">
              <li>
                <a class="c_pointer" @click="$router.push({ name: 'Home'})">ホーム</a>
              </li>
              <li>上級道場</li>
            </ul>
          </nav>
          <h1 class="c_main_ttl">上級道場</h1>
        </div>
      </header>
      <div class="c_main_body">
        <div class="inner">
          <!--
          <section class="about">
            <div class="about_cnt">
              <h2 class="about_ttl">このコンテンツについて</h2>
              <p
                class="about_text"
              >上級道場は有償コンテンツとなります。ご利用を希望される方は下記までお問い合わせください。上級道場は有償コンテンツとなります。ご利用を希望される方は下記までお問い合わせください。上級道場は有償コンテンツとなります。</p>
            </div>
            <p class="about_contact">
              <em>上級道場についてのお問い合わせ</em>
              <br />
              <a href="mailto:guide@jc3.or.jp">guide@jc3.or.jp</a>
            </p>
          </section>
          -->
          <section-contents-list :categoryId="categoryId"></section-contents-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionContentsList from "@/components/SectionContentsList";

export default {
  components: {
    SectionContentsList
  },
  computed: {
    categoryId() {
      return this.$store.getters.getCategoryIdByName("上級道場");
    }
  },
};
</script>

<style scoped>
@import "~@/css/advanced_dojo.css";
</style>